<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>
        <v-snackbar :timeout="callbackResponse.timeout" :color="callbackResponse.color" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>

        <v-dialog max-width="500px" v-model="dialogEdit">
            <editSectorForm :serviceID="editedData.foreignExchangeID"
                            :success="success"
                            @formResponse="onResponse"
                            v-if="dialogEdit" />
        </v-dialog>
        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>

        <v-data-table :headers="columns"
                      :items="rows"
                      :options.sync="options"
                      :server-items-length="totalRecords"
                      :loading="loading"
                      class="elevation-1"
                      hide-default-header
                      hide-default-footer
                      >

            <template v-slot:header="{props}">
                <thead>
                    <tr class="text-center">
                        <th v-for="header in props.headers" :key="header.label">
                            {{$t(header.label)}}
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item="props">
                <tr class="text-center">
                    <td class="text-center border ">
                        <v-text-field dense
                                      v-if="props.item.isEdit || props.item.isCreate"
                                      v-model="props.item.year"
                                      class="shrink"></v-text-field>
                        <div v-else> {{props.item.year}}</div>
                    </td>
                    <td>
                        <v-text-field dense
                                      v-model="props.item.totalExchangeEarning"
                                      v-if="props.item.isEdit || props.item.isCreate"
                                      class="shrink"></v-text-field>
                        <div v-else> {{props.item.totalExchangeEarning}}</div>

                    </td>
                    <td>
                        <v-text-field dense
                                      v-model="props.item.totalValueOfMerchandise"
                                      v-if="props.item.isEdit || props.item.isCreate"
                                      class="shrink"></v-text-field>
                        <div v-else> {{props.item.totalValueOfMerchandise}}</div>

                    </td>
                    <td>
                        <v-text-field dense
                                      v-model="props.item.totalValueOfExports"
                                      v-if="props.item.isEdit || props.item.isCreate"
                                      class="shrink"></v-text-field>
                        <div v-else> {{props.item.totalValueOfExports}}</div>

                    </td>
                    <td>
                        <v-text-field dense
                                      v-model="props.item.totalForeignExchange"
                                      v-if="props.item.isEdit || props.item.isCreate"
                                      class="shrink"></v-text-field>
                        <div v-else> {{props.item.totalForeignExchange}}</div>

                    </td>
                    <td>
                        <v-text-field dense
                                      v-model="props.item.gdp"
                                      v-if="props.item.isEdit || props.item.isCreate"
                                      class="shrink"></v-text-field>
                        <div v-else> {{props.item.gdp}}</div>

                    </td>
                    <td width="12%">
                        <v-icon class="mr-2" v-if="props.item.isCreate" @click="props.item.isCreate=false;submit(props.item)">save</v-icon>
                        <v-icon class="mr-2" v-if="props.item.isEdit" @click="props.item.isEdit=false;submitEdit(props.item)">save</v-icon>
                        <v-icon @click="props.item.isEdit=true;props.item.isCreate=false;" class="mr-2">edit</v-icon>
                        <v-icon v-if="!props.item.isCreate" @click="deleteItem(props.item)">delete</v-icon>
                        <v-icon v-if="props.item.isCreate" @click="createRemoveArray(props.index)">delete</v-icon>
                        <v-icon v-if="rows.length == 0 || rows.length-1 === props.index" @click="addTableData">add</v-icon>

                    </td>
                </tr>

            </template>

        </v-data-table>

    </v-content>
</template>

<script>
    import axios from "axios";
    import editSectorForm from "./edit";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "BuyingRate",
        components: {
            editSectorForm,
            deleteListData
        },
        computed: {
            breadCrumb() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: '/Income-GenerationDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('gross_foreign_exchange_earnings_from_tourism'),
                        disabled: true,
                    },
                ]
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                options: {},
                loading: false,
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                deleteFormData: {},
                popout: true,
                isLoading: false,
                columns: [
                    {
                        label: "year",
                        field: "fiscalYear"
                    },
                    {
                        label: "total_foreign_exchange_earning_from_tourism",
                        field: "nrs"
                    },
                    {
                        label: "total_value_of_merchandise_exports",
                        field: "exchangeRate"
                    },
                    {
                        label: "total_value_of_exports",
                        field: "amountUsd"
                    },
                    {
                        label: "total_foreign_exchange_earnings",
                        field: "change"
                    },
                    {
                        label: "of_gdp",
                        field: "change"
                    },
                    {
                        label: "actions",
                        field: "actions",
                        sortable: false
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "fiscalYear",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    serviceName: "",
                    serviceDescription: ""
                },
            };
        },

        mounted() {
            this.loadItems();
        },
        methods: {
            async submit(data) {

                    let param = {
                        totalExchangeEarning: parseFloat(data.totalExchangeEarning),
                        year:data.year.toString(),
                        totalValueOfMerchandise: parseFloat(data.totalValueOfMerchandise),
                        totalValueOfExports: parseFloat(data.totalValueOfExports),
                        totalForeignExchange: parseFloat(data.totalForeignExchange),
                        gdp: parseFloat(data.gdp)
                    }
               await axios.post("NRBExchange/InsertIntoForeignExchangeAsync", param).then(response => {
                        if (response.data.success) {
                            this.snackbar = true
                            this.callbackResponse.data = response.data
                            this.callbackResponse.message = "Income Data added Succcessfully"
                            this.callbackResponse.color = "success"

                        }

                    }).catch(response => {
                        this.snackbar = true
                        this.callbackResponse.data = response.data
                        this.callbackResponse.message = "Error Adding Income Data, Please Contact Admin"
                        this.callbackResponse.color = "error"
                    });
                this.loadItems();

            },
            async submitEdit(data) {
                let param = {
                        foreignExchangeID: data.foreignExchangeID,
                        totalExchangeEarning: parseFloat(data.totalExchangeEarning),
                        year: data.year.toString(),
                        totalValueOfMerchandise: parseFloat(data.totalValueOfMerchandise),
                        totalValueOfExports: parseFloat(data.totalValueOfExports),
                        totalForeignExchange: parseFloat(data.totalForeignExchange),
                        gdp: parseFloat(data.gdp)
                    }
                await axios.post("NRBExchange/UpdateForeignExchangeAsync", param).then(response => {
                            this.callbackResponse.data = response.data
                            this.snackbar = true
                            this.callbackResponse.message = "Data updated Successfully"
                            this.callbackResponse.color = "success"

                    }).catch(response => {
                        this.snackbar = true
                        this.callbackResponse.data = response.data
                        this.callbackResponse.message = "Error Updating Data, Please Contact Admin"
                        this.callbackResponse.color = "error"
                    });
                this.loadItems();

            },
            createRemoveArray(index) {
                this.rows.splice(index,1)
            },
            addTableData() {
                this.rows.push({
                    year: '',
                    totalExchangeEarning: '',
                    totalValueOfMerchandise: '',
                    totalValueOfExports: '',
                    totalForeignExchange: '',
                    gdp:'',
                    isEdit: false,
                    isCreate: true,
                })
            },
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.ForeignExchangeID = props.ForeignExchangeID;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "NRBExchange/DeleteForeignExchangeAsync/" + props.foreignExchangeID;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = {}
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                };
                await axios.post("NRBExchange/GetForeignExchangeListAsync", param)
                    .then(({data}) => {
                        this.rows = data.data;
                        this.rows.map(res => {
                            this.$set(res, 'isEdit', false);
                            this.$set(res, 'isCreate', false);
                            return res;
                        })
                        this.totalRecords = data.totalCount;
                        if (this.rows.length == 0) {
                            this.addTableData();
                            this.totalRecords = 1
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });

            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
    ::v-deep .v-text-field, ::v-deep .v-text-field--solo {
        width: 100px !important;
    }

    ::v-deep .v-data-table__wrapper {
        width: 100% !important;
    }
    .border {
        border: 1px solid black !important;
    }

    table, td {
        height: 40px !important;
        border: 1px solid black !important;
        border-collapse: collapse;
    }

    thead {
        background-color: #1976D2;
        color: white;

        th {
            border: 1px solid !important;
            color: white !important;
            text-align: center !important;
            border-color: white;
            font-size: 15px !important;
            padding: 1rem !important;
        }
    }

    ::v-deep .v-text-field {
        width: 160px !important;
    }
</style>
