<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Edit Income Data
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field v-model.number="editedData.fiscalYear"
                                  :error-messages="fiscalYearErrors"
                                  label="Fiscal Year"
                                  required
                                  @input="$v.editedData.fiscalYear.$touch()"
                                  @blur="$v.editedData.fiscalYear.$touch()"></v-text-field>
                    <v-text-field v-model.number="editedData.nrs"
                                  :error-messages="nrsErrors"
                                  label="NRs. (Million)"
                                  required
                                  type="number"
                                  @input="$v.editedData.nrs.$touch()"
                                  @blur="$v.editedData.nrs.$touch()"></v-text-field>
                    <v-text-field v-model.number="editedData.amountUsd"
                                  :error-messages="amountUsdErrors"
                                  label="US$ (Million)"
                                  required
                                  type="number"
                                  @input="$v.editedData.amountUsd.$touch()"
                                  @blur="$v.editedData.amountUsd.$touch()"></v-text-field>
                    <v-text-field v-model.number="editedData.exchangeRate"
                                  :error-messages="exchangeRateErrors"
                                  label="Exchange Rate"
                                  required
                                  type="number"
                                  @input="$v.editedData.exchangeRate.$touch()"
                                  @blur="$v.editedData.exchangeRate.$touch()"></v-text-field>

                    <v-text-field v-model.number="editedData.change"
                                  :error-messages="changeErrors"
                                  label="%Change in US$"
                                  required
                                  type="number"
                                  @input="$v.editedData.change.$touch()"
                                  @blur="$v.editedData.change.$touch()"></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "sectorEdit",
        props: ['serviceID'],
        computed: {
            nrsErrors() {
                const errors = [];
                if (!this.$v.editedData.nrs.$dirty) return errors;
                !this.$v.editedData.nrs.required &&
                    errors.push("Nrs is required.");
                return errors;
            },
            amountUsdErrors() {
                const errors = [];
                if (!this.$v.editedData.amountUsd.$dirty) return errors;
                !this.$v.editedData.amountUsd.required &&
                    errors.push("Amount in US$ is required.");
                return errors;
            },
            exchangeRateErrors() {
                const errors = [];
                if (!this.$v.editedData.exchangeRate.$dirty) return errors;
                !this.$v.editedData.exchangeRate.required &&
                    errors.push("Exchange Rate is required.");
                return errors;
            },
            fiscalYearErrors() {
                const errors = [];
                if (!this.$v.editedData.fiscalYear.$dirty) return errors;
                !this.$v.editedData.fiscalYear.required &&
                    errors.push("Fiscal Year is required.");
                return errors;
            },
            incomeDateErrors() {
                const errors = [];
                if (!this.$v.editedData.incomeDate.$dirty) return errors;
                !this.$v.editedData.incomeDate.required &&
                    errors.push("Date is required.");
                return errors;
            },
        },
        data() {
            return {
                editedData: {},
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                },
                sectorList: [],
                snackbar: {
                    dateMenu: false
                }
            };
        },
        validations: {
            editedData: {
                nrs: { required },
                fiscalYear: { required },
                amountUsd: { required },
                exchangeRate: { required },
                change: { required },
            }
        },
        mounted() {
            this.formData();
        },
        methods: {
            async formData() {
                const {data} = await axios.get('NRBExchange/GetInComeGenerationByInComeIDAsync/' + this.serviceID)
                this.editedData = data
                const sectorList = await axios.get('NRBExchange/DDLSectorListAsync')
                this.sectorList = sectorList.data
            },
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        nrs: this.editedData.nrs,
                        fiscalYear: this.editedData.fiscalYear.toString(),
                        amountUsd: this.editedData.amountUsd,
                        exchangeRate: this.editedData.exchangeRate,
                        change: this.editedData.change.toString(),
                        inComeID: this.editedData.inComeID
                    }
                    axios.post("NRBExchange/UpdateInComeGenerationAsync", param).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Data updated Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Updating Data, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData = {}
            }
        }
    };
</script>

<style scoped>
</style>